import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import {
  boxStyle,
  cancelStyle,
  submitStyle,
} from '@/fleet/components/common/styles';
import { Formik } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { listDriversService } from '@/fleet/services/drivers';
import { createCarrierTractor } from '@/fleet/services/carriers';
import { isEmpty } from 'lodash';

interface AddTractorModalProps {
  handleClose: () => void;
  carrierUuid: string | undefined;
  setShowSuccessAlert: (bool: boolean) => void;
  setShowErrorAlert: (bool: boolean) => void;
  setAlertMessage: (string: string) => void;
}

const alphaNumericRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;

const validationSchemaObject = Yup.object().shape({
  unitId: Yup.string().required('Required field'),
  vin: Yup.string()
    .length(17, 'VIN must be exactly 17 characters')
    .matches(alphaNumericRegex)
    .required('Required field'),
  driverUuids: Yup.array().of(Yup.string()).optional(),
  startDate: Yup.date().nullable().required('Required field'),
});

const FORM_INPUT_FIELDS = [
  {
    label: 'Unit ID',
    value: 'unitId',
  },
  {
    label: 'VIN',
    value: 'vin',
  },
];

const initialValues = {
  unitId: '',
  vin: '',
  driverUuids: [],
  startDate: null,
};

const AddTractorModal = ({
  handleClose,
  carrierUuid,
  setShowSuccessAlert,
  setShowErrorAlert,
}: AddTractorModalProps) => {
  const [loading, setLoading] = useState(true);
  const [assignableDrivers, setAssignableDrivers] = useState<any[]>();

  useEffect(() => {
    let drivers: any[] = [];
    const getDrivers = async () => {
      if (carrierUuid) {
        await listDriversService(carrierUuid).then((response) => {
          drivers = (response as AxiosResponse<any>).data?.drivers;

          if (drivers) {
            // No need to filter drivers since multiple drivers can be assigned
            setAssignableDrivers(drivers);
          }
        });
      }
      setLoading(false);
    };
    getDrivers();
  }, [carrierUuid]);

  // determines what appears in the Assigned Drivers dropdown field
  const getAssignableDriversFieldText = () => {
    if (loading) {
      return (
        <CircularProgress
          sx={{
            color: '#181818',
          }}
        />
      );
    }

    if (isEmpty(assignableDrivers)) {
      return 'No available drivers to assign';
    }

    return 'Assigned Drivers';
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaObject}
      onSubmit={(values) => {
        const newValues = {
          vin: values.vin,
          driverUuids: values.driverUuids,
          unitId: values.unitId,
          startDate: '20231102',
        };
        createCarrierTractor(carrierUuid!, newValues).then((response) => {
          if (response.status === 200) {
            setShowSuccessAlert(true);
            handleClose();
          } else {
            setShowErrorAlert(true);
            handleClose();
          }
        });
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
      }: any) => (
        <Modal open onClose={handleClose} aria-labelledby="modal-title">
          <form onSubmit={handleSubmit}>
            <Box sx={boxStyle}>
              <Typography
                id="modal-title"
                variant="h6"
                component="h1"
                sx={{ mb: 2, mt: 1 }}
              >
                Add Tractor
              </Typography>
              {FORM_INPUT_FIELDS.map((field) => {
                const { value, label } = field;
                return (
                  <TextField
                    key={`${value}-${label}`}
                    id={value}
                    label={label}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                    error={touched[value] && !!errors[value]}
                    helperText={touched[value] && errors[value]}
                  />
                );
              })}
              <Box mb={2}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Start Date"
                      value={values.startDate}
                      onChange={(newValue) => {
                        setFieldValue('startDate', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={touched.startDate && !!errors.startDate}
                          helperText={touched.startDate && errors.startDate}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl fullWidth>
                  <InputLabel
                    id="drivers"
                    error={touched.driverUuids && !!errors.driverUuids}
                  >
                    {getAssignableDriversFieldText()}
                  </InputLabel>
                  <Select
                    labelId="drivers"
                    name="driverUuids"
                    value={values.driverUuids}
                    label="Assigned Drivers"
                    multiple
                    onChange={handleChange}
                    disabled={isEmpty(assignableDrivers)}
                    error={touched.driverUuids && !!errors.driverUuids}
                  >
                    {assignableDrivers?.map((driver) => {
                      return (
                        <MenuItem value={driver.uuid} key={driver.uuid}>
                          {driver.firstName} {driver.lastName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText
                    error={touched.driverUuids && !!errors.driverUuids}
                  >
                    {touched.driverUuids && errors.driverUuids}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box display="flex" mt={3} justifyContent="flex-end">
                <Button sx={cancelStyle} onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  size="small"
                  color="secondary"
                  sx={submitStyle}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
        </Modal>
      )}
    </Formik>
  );
};

export default AddTractorModal;
