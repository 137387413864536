import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MUIDataTable from 'mui-datatables';
import { getCarrier, getCarrierTractors } from '@/fleet/services/carriers';
import { listDriversService } from '@/fleet/services/drivers';
import { StatusPill } from '@/fleet/components/common/StatusPill';
import SuccessAlert from '@/fleet/components/common/alerts/success';
import ErrorAlert from '@/fleet/components/common/alerts/error';
import { StyledButton } from '@/fleet/components/common/StyledButton';
import AddTractorModal from '@/fleet/components/views/tractors/AddTractorModal';
import ImportTractorAssignmentModal from '@/fleet/components/views/tractors/ImportTractorAssignmentModal';
import { getVehicleEligibility } from '@/fleet/services/metrics';
import toast from 'react-hot-toast';

export interface Eligibility {
  vin?: string;
  eligible?: boolean;
  ineligibilityReasons?: string[];
}

interface TractorType {
  uuid: string;
  vin: string; // Assuming the vin property exists on a tractor object
  // include other properties as needed
}

export function Tractors() {
  const [loading, setLoading] = useState(true);
  const [showAddTractorModal, setShowAddTractorModal] = useState(false);
  const [
    showImportTractorAssignmentModal,
    setShowImportTractorAssignmentModal,
  ] = useState(false);

  const [tractorData, setTractorData] = useState();
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  interface Driver {
    uuid: string;
    firstName: string;
    lastName: string;
    enabled: boolean;
  }

  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [eligibilities, setEligibilities] = useState<Eligibility[] | null>(
    null,
  );
  const [eligibilitiesLoading, setEligibilitiesLoading] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const { carrierUuid } = useParams();

  const columns = [
    {
      label: 'Unit ID',
      name: 'unitId',
    },
    {
      label: 'VIN',
      name: 'vin',
    },
    {
      label: 'Status',
      name: 'endDate',
      options: {
        customBodyRender: (value: string) =>
          value ? <StatusPill active={false} /> : <StatusPill active />,
      },
    },
    {
      label: 'Assigned Drivers',
      name: 'driverUuids',
      options: {
        customBodyRender: (value: string[]) => {
          if (loading) {
            return (
              <CircularProgress
                sx={{
                  color: '#181818',
                }}
              />
            );
          }

          if (!value || value.length === 0) {
            return <StatusPill active={false} text="Unassigned" />;
          }

          const assignedDrivers = value
            .map((driverUuid: string) => {
              const driver = drivers.find(
                (d: { uuid: string; firstName: string; lastName: string }) =>
                  d.uuid === driverUuid,
              );
              return driver ? `${driver.firstName} ${driver.lastName}` : '';
            })
            .filter((name: string) => name !== '');

          return assignedDrivers.length > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
              }}
            >
              {assignedDrivers.map((name: string, index: number) => (
                <p key={index}>{name}</p>
              ))}
            </div>
          ) : (
            <StatusPill active={false} text="Unassigned" />
          );
        },
      },
    },
    {
      name: 'uuid',
      label: ' ',
      options: {
        customBodyRender: (tractorUuid: string) => {
          return eligibilitiesLoading ? (
            <CircularProgress
              sx={{
                color: '#181818',
              }}
            />
          ) : (
            <Button
              size="small"
              sx={{
                padding: 0,
                textTransform: 'none',
                color: '#181818',
                '&:hover': {
                  color: '#181818',
                  background: 'none',
                },
              }}
              disabled={eligibilitiesLoading}
              onClick={() => {
                const tractor: TractorType[] = (tractorData || []).filter(
                  (x: any) => x.uuid === tractorUuid,
                );
                const eligibility = eligibilities?.find(
                  (e) => e.vin === tractor[0].vin,
                );
                navigate(`/carriers/${carrierUuid}/tractors/${tractorUuid}`, {
                  state: { tractor, tractorData, eligibility },
                });
              }}
            >
              <EditIcon />
            </Button>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (!carrierUuid) {
      return;
    }

    const getInitialData = async () => {
      await getCarrier(carrierUuid)
        .then((resp) => {
          const carrier = resp.data.carrier;
          getCarrierTractors(carrierUuid)
            .then((response) => {
              const units = response.data.tractorUnits;
              setTractorData(units);
              const vins: string[] = [];
              units.forEach((u: any) => {
                if (u.vin) {
                  vins.push(u.vin);
                }
              });
              if (vins.length > 0 && carrier.isDtnaCarrier) {
                setEligibilitiesLoading(true);
                getVehicleEligibility(vins)
                  .then((resp) => {
                    if (!axios.isAxiosError(resp)) {
                      const eligibleVehicles = resp.data.eligibleVehicles;
                      const ineligibleVehicles = resp.data.ineligibleVehicles;
                      const aggregatedEligibilities: Eligibility[] = [];
                      eligibleVehicles?.forEach((vin: string) => {
                        aggregatedEligibilities.push({ vin, eligible: true });
                      });
                      ineligibleVehicles?.forEach(
                        (vehicle: { vin: string; errors: string[] }) => {
                          aggregatedEligibilities.push({
                            vin: vehicle.vin,
                            eligible: false,
                            ineligibilityReasons: vehicle.errors,
                          });
                        },
                      );
                      setEligibilities(aggregatedEligibilities);
                      setEligibilitiesLoading(false);
                      setLoading(false);
                    } else {
                      setEligibilitiesLoading(false);
                      setLoading(false);
                    }
                  })
                  .catch((error) => {
                    toast.error(error);
                    setEligibilitiesLoading(false);
                    setLoading(false);
                  });
              }
            })
            .catch(() => {
              setShowErrorAlert(true);
              setEligibilitiesLoading(false);
            });
        })
        .catch((error) => {
          console.log(`checking api response error: ${error}`);
        });

      await listDriversService(carrierUuid).then((response) =>
        setDrivers((response as AxiosResponse<any>).data?.drivers),
      );
    };

    getInitialData();

    setLoading(false);
  }, [carrierUuid, showSuccessAlert]);

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Box
        marginBottom={4}
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <StyledButton
          style={{ ml: 2 }}
          onClick={() => {
            setShowAddTractorModal(true);
          }}
        >
          Add Tractor
        </StyledButton>
        <StyledButton
          style={{ ml: 2 }}
          onClick={() => {
            setShowImportTractorAssignmentModal(true);
          }}
        >
          Import Tractor Assignments
        </StyledButton>
      </Box>

      <MUIDataTable
        columns={columns}
        data={tractorData || []}
        title="Tractors"
        options={{
          enableNestedDataAccess: '.',
          selectableRowsHideCheckboxes: true,
          filter: false,
          download: false,
          print: false,
          rowsPerPage: 10,
          rowsPerPageOptions: [10, 20, 50],
          textLabels: {
            body: {
              noMatch: loading ? (
                <CircularProgress
                  sx={{
                    color: '#181818',
                  }}
                />
              ) : (
                'No tractors found'
              ),
            },
          },
        }}
      />
      {showAddTractorModal && (
        <AddTractorModal
          handleClose={() => setShowAddTractorModal(false)}
          carrierUuid={carrierUuid}
          setShowSuccessAlert={setShowSuccessAlert}
          setShowErrorAlert={setShowErrorAlert}
          setAlertMessage={setAlertMessage}
        />
      )}
      {showImportTractorAssignmentModal && (
        <ImportTractorAssignmentModal
          handleClose={() => setShowImportTractorAssignmentModal(false)}
          carrierUuid={carrierUuid}
          setShowSuccessAlert={setShowSuccessAlert}
          setShowErrorAlert={setShowErrorAlert}
        />
      )}
      <SuccessAlert
        message="Added tractor successfully!"
        open={showSuccessAlert}
        handleClose={() => setShowSuccessAlert(false)}
      />
      <ErrorAlert
        message={`An error occurred when trying to add tractor. Please try again later. ${alertMessage}`}
        open={showErrorAlert}
        handleClose={() => setShowErrorAlert(false)}
      />
    </div>
  );
}
